type RegionMapping = Record<string, string>;

// Unified region mapping combining AWS and Azure regions
const unifiedRegionMapping: RegionMapping = {
  // AWS Regions
  'us-east-1': 'US East (N. Virginia)',
  'us-east-2': 'US East (Ohio)',
  'us-west-1': 'US West (N. California)',
  'us-west-2': 'US West (Oregon)',
  'af-south-1': 'Africa (Cape Town)',
  'ap-east-1': 'Asia Pacific (Hong Kong)',
  'ap-south-1': 'Asia Pacific (Mumbai)',
  'ap-south-2': 'Asia Pacific (Hyderabad)',
  'ap-southeast-1': 'Asia Pacific (Singapore)',
  'ap-southeast-2': 'Asia Pacific (Sydney)',
  'ap-southeast-3': 'Asia Pacific (Jakarta)',
  'ap-southeast-4': 'Asia Pacific (Melbourne)',
  'ap-southeast-5': 'Asia Pacific (Kuala Lumpur)',
  'ap-northeast-1': 'Asia Pacific (Tokyo)',
  'ap-northeast-2': 'Asia Pacific (Seoul)',
  'ap-northeast-3': 'Asia Pacific (Osaka)',
  'ca-central-1': 'Canada (Central)',
  'ca-west-1': 'Canada (Calgary)',
  'eu-central-1': 'Europe (Frankfurt)',
  'eu-central-2': 'Europe (Zurich)',
  'eu-west-1': 'Europe (Ireland)',
  'eu-west-2': 'Europe (London)',
  'eu-west-3': 'Europe (Paris)',
  'eu-south-1': 'Europe (Milan)',
  'eu-south-2': 'Europe (Spain)',
  'eu-north-1': 'Europe (Stockholm)',
  'il-central-1': 'Israel (Tel Aviv)',
  'me-south-1': 'Middle East (Bahrain)',
  'me-central-1': 'Middle East (UAE)',
  'sa-east-1': 'South America (São Paulo)',

  // Azure Regions
  eastus: 'East US',
  eastus2: 'East US 2',
  westus: 'West US',
  westus2: 'West US 2',
  westus3: 'West US 3',
  centralus: 'Central US',
  northcentralus: 'North Central US',
  southcentralus: 'South Central US',
  westcentralus: 'West Central US',
  canadacentral: 'Canada Central',
  canadaeast: 'Canada East',
  brazilsouth: 'Brazil South',
  brazilsoutheast: 'Brazil Southeast',
  northeurope: 'North Europe',
  westeurope: 'West Europe',
  uksouth: 'UK South',
  ukwest: 'UK West',
  francecentral: 'France Central',
  francesouth: 'France South',
  germanywestcentral: 'Germany West Central',
  germanynorth: 'Germany North',
  norwayeast: 'Norway East',
  norwaywest: 'Norway West',
  swedencentral: 'Sweden Central',
  swedensouth: 'Sweden South',
  switzerlandnorth: 'Switzerland North',
  switzerlandwest: 'Switzerland West',
  uaenorth: 'UAE North',
  uaecentral: 'UAE Central',
  australiaeast: 'Australia East',
  australiasoutheast: 'Australia Southeast',
  australiacentral: 'Australia Central',
  australiacentral2: 'Australia Central 2',
  japaneast: 'Japan East',
  japanwest: 'Japan West',
  koreacentral: 'Korea Central',
  koreasouth: 'Korea South',
  southeastasia: 'Southeast Asia',
  eastasia: 'East Asia',
  centralindia: 'Central India',
  southindia: 'South India',
  westindia: 'West India',
  southafricanorth: 'South Africa North',
  southafricawest: 'South Africa West',
  qatarcentral: 'Qatar Central',
  polandcentral: 'Poland Central',
  italynorth: 'Italy North',
};

function formatRegionName(regionCode: string): string {
  const formattedRegion =
    unifiedRegionMapping[regionCode.toLowerCase()] || regionCode;
  return formattedRegion;
}

export { formatRegionName, unifiedRegionMapping };
