import { Box, type SxProps, type Theme } from '@mui/material';

export function Loader({
  sx,
  variant = 'default',
}: {
  sx?: SxProps<Theme>;
  variant?: 'default' | 'inline';
}) {
  return (
    <Box sx={sx} className='flex justify-center h-full items-center'>
      {variant === 'default' && (
        <i className='line-md-loading-loop w-[50px] h-[50px]' />
      )}
      {variant === 'inline' && (
        <i className='line-md-loading-loop w-[24px] h-[24px]' />
      )}
    </Box>
  );
}
