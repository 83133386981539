import { BackupStatus } from '@repo/api-gw-sdk';

export enum InventoryResourceActionsLevels {
  MainPrimary = 'MainPrimary',
  MainSecondary = 'MainSecondary',
  SubPrimary = 'SubPrimary',
  SubSecondary = 'SubSecondary',
  SubMore = 'SubMore',
}

export enum InventoryResourceActionsKeys {
  Restore = 'Restore',
  AssignToBackupPolicy = 'AssignToBackupPolicy',
  ExcludeFromBackupPolicy = 'ExcludeFromBackupPolicy',
  TakeSnapshot = 'TakeSnapshot',
  ExcludeFromBackup = 'ExcludeFromBackup',
  AllowBackups = 'AllowBackups',
  Explore = 'Explore',
  RevealInResourceExplorer = 'RevealInResourceExplorer',
}

export type ActionsToLevel = Partial<
  Record<InventoryResourceActionsKeys, InventoryResourceActionsLevels>
>;
export type BackupStatusToActionsMap = Record<BackupStatus, ActionsToLevel>;

export const backupStatusActions: BackupStatusToActionsMap = {
  [BackupStatus.Protected]: {
    [InventoryResourceActionsKeys.Explore]:
      InventoryResourceActionsLevels.MainPrimary,
    [InventoryResourceActionsKeys.Restore]:
      InventoryResourceActionsLevels.MainPrimary,
    [InventoryResourceActionsKeys.TakeSnapshot]:
      InventoryResourceActionsLevels.MainSecondary,
    [InventoryResourceActionsKeys.AssignToBackupPolicy]:
      InventoryResourceActionsLevels.SubMore,
    [InventoryResourceActionsKeys.ExcludeFromBackup]:
      InventoryResourceActionsLevels.SubMore,
  },
  [BackupStatus.ViolationsDetected]: {
    [InventoryResourceActionsKeys.Explore]:
      InventoryResourceActionsLevels.MainPrimary,
    [InventoryResourceActionsKeys.Restore]:
      InventoryResourceActionsLevels.MainPrimary,
    [InventoryResourceActionsKeys.TakeSnapshot]:
      InventoryResourceActionsLevels.MainSecondary,
    [InventoryResourceActionsKeys.AssignToBackupPolicy]:
      InventoryResourceActionsLevels.SubMore,
    [InventoryResourceActionsKeys.ExcludeFromBackup]:
      InventoryResourceActionsLevels.SubMore,
    // [InventoryResourceActionsKeys.MuteAllViolations]: InventoryResourceActionsLevels.SubMore,
  },
  [BackupStatus.NotBackedUp]: {
    [InventoryResourceActionsKeys.TakeSnapshot]:
      InventoryResourceActionsLevels.MainSecondary,
    [InventoryResourceActionsKeys.Explore]:
      InventoryResourceActionsLevels.MainPrimary,
    [InventoryResourceActionsKeys.Restore]:
      InventoryResourceActionsLevels.MainPrimary,
    [InventoryResourceActionsKeys.AssignToBackupPolicy]:
      InventoryResourceActionsLevels.SubPrimary,
    [InventoryResourceActionsKeys.ExcludeFromBackup]:
      InventoryResourceActionsLevels.SubMore,
  },
  [BackupStatus.AllViolationsMuted]: {
    [InventoryResourceActionsKeys.Explore]:
      InventoryResourceActionsLevels.MainPrimary,
    [InventoryResourceActionsKeys.Restore]:
      InventoryResourceActionsLevels.MainPrimary,
    [InventoryResourceActionsKeys.TakeSnapshot]:
      InventoryResourceActionsLevels.MainSecondary,
    [InventoryResourceActionsKeys.AssignToBackupPolicy]:
      InventoryResourceActionsLevels.SubMore,
    [InventoryResourceActionsKeys.ExcludeFromBackup]:
      InventoryResourceActionsLevels.SubMore,
    // [InventoryResourceActionsKeys.UnmuteAllViolations]: InventoryResourceActionsLevels.SubMore,
  },
  [BackupStatus.ExcludedFromBackup]: {
    [InventoryResourceActionsKeys.Explore]:
      InventoryResourceActionsLevels.MainPrimary,
    [InventoryResourceActionsKeys.Restore]:
      InventoryResourceActionsLevels.MainPrimary,
    [InventoryResourceActionsKeys.TakeSnapshot]:
      InventoryResourceActionsLevels.MainSecondary,
    [InventoryResourceActionsKeys.AllowBackups]:
      InventoryResourceActionsLevels.SubPrimary,
  },
  [BackupStatus.GenericBackups]: {
    [InventoryResourceActionsKeys.Explore]:
      InventoryResourceActionsLevels.MainPrimary,
    [InventoryResourceActionsKeys.Restore]:
      InventoryResourceActionsLevels.MainPrimary,
    [InventoryResourceActionsKeys.TakeSnapshot]:
      InventoryResourceActionsLevels.MainSecondary,
    [InventoryResourceActionsKeys.AssignToBackupPolicy]:
      InventoryResourceActionsLevels.SubPrimary,
    [InventoryResourceActionsKeys.ExcludeFromBackup]:
      InventoryResourceActionsLevels.SubMore,
  },
  [BackupStatus.InitialClassification]: {
    [InventoryResourceActionsKeys.ExcludeFromBackup]:
      InventoryResourceActionsLevels.SubMore,
  },
  [BackupStatus.Terminated]: {
    [InventoryResourceActionsKeys.TakeSnapshot]:
      InventoryResourceActionsLevels.MainSecondary,
    [InventoryResourceActionsKeys.Explore]:
      InventoryResourceActionsLevels.MainPrimary,
    [InventoryResourceActionsKeys.Restore]:
      InventoryResourceActionsLevels.MainPrimary,
  },
  [BackupStatus.Disconnected]: {
    [InventoryResourceActionsKeys.TakeSnapshot]:
      InventoryResourceActionsLevels.MainSecondary,
    [InventoryResourceActionsKeys.Explore]:
      InventoryResourceActionsLevels.MainPrimary,
    [InventoryResourceActionsKeys.Restore]:
      InventoryResourceActionsLevels.MainPrimary,
  },
  [BackupStatus.BackupPostureStatusUnspecified]: {},
  [BackupStatus.Unsupported]: {},
};

export const groupActionsByLevel = <T>(
  backupStatus: BackupStatus,
  actions: Action<T>[]
): Partial<Record<InventoryResourceActionsLevels, Action<T>[]>> => {
  const actionsByLevel = actions.reduce<
    Partial<Record<InventoryResourceActionsLevels, Action<T>[]>>
  >((acc, action) => {
    const actionLevel: InventoryResourceActionsLevels | undefined =
      backupStatusActions[backupStatus][action.key];
    if (!actionLevel) {
      return acc;
    }

    if (!acc[actionLevel]) {
      acc[actionLevel] = [];
    }
    acc[actionLevel].push(action);
    return acc;
  }, {});

  return actionsByLevel;
};

export interface Action<T> {
  key: InventoryResourceActionsKeys;
  title?: string;
  tooltip: string;
  icon: string;
  execute: (resource: T) => void;
  predicate?: () => boolean;
  disabled?: () => boolean;
}
