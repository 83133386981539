import {
  Divider,
  IconButton,
  Skeleton,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import type { BackupPolicy, BackupSchedule } from '@repo/api-gw-sdk';
import { useEffect, useState } from 'react';

import {
  frequencyCronText,
  retentionText,
} from '@/app/(dashboard)/settings/backup-policies/BackupPolicyFormatting';
import type { BackupVault } from '@/data/vaults/backupVault';

import { SnapshotIndicator } from '../snapshots/SnapshotIndicator';
import BackupVaultCard from '../vaults/BackupVaultCard';

const SelectableIconBtn = styled(IconButton)<{ selected?: boolean }>(
  ({ theme, selected }) => ({
    border: selected ? `1px solid ${theme.palette.divider}` : 'none',
    padding: '4px',
    margin: selected ? '0 4px' : 'none',
  })
);

export interface BackupVaultPopperCardProps {
  policy: BackupPolicy;
  vaults: BackupVault[];
}

export default function BackupVaultPopperCard(
  props: BackupVaultPopperCardProps
) {
  const { vaults, policy } = props;
  const [selectedSchedule, setSelectedSchedule] =
    useState<BackupSchedule | null>(null);

  useEffect(() => {
    if (policy.schedules) {
      if (!selectedSchedule && policy.schedules.length > 0) {
        setSelectedSchedule(policy.schedules[0]);
      }
    }
  }, [policy.schedules, selectedSchedule, vaults]);

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
      style={{
        backgroundColor: 'var(--mui-palette-background-paper)',
        boxShadow: '0px 10px 30px 0px rgba(0, 0, 0, 0.10)',
        width: '368px',
        padding: '24px',
        paddingBottom: '12px',
        borderRadius: '16px',
      }}
    >
      <Typography variant='h6' className='mb-[20px]'>
        {policy?.name || <Skeleton width='200px' />}
      </Typography>
      <Stack gap='24px' direction='row' alignItems='center'>
        <Stack>
          <Typography variant='body1' sx={{ mb: '12px' }}>
            FREQUENCY
          </Typography>
          <Typography
            variant='body2'
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '200px',
            }}
          >
            {selectedSchedule?.windows &&
              frequencyCronText(selectedSchedule.windows)}
          </Typography>
        </Stack>
        <Divider orientation='vertical' sx={{ height: '40px' }} />
        <Stack>
          <Typography variant='body1' sx={{ mb: '12px' }}>
            RETENTION
          </Typography>
          <Typography variant='body2'>
            {selectedSchedule?.backupRetention &&
              retentionText(selectedSchedule.backupRetention)}
          </Typography>
        </Stack>
      </Stack>
      <div className='mt-[24px]'>
        {selectedSchedule ? (
          <BackupVaultCard
            editable={false}
            data={vaults.find((v) => v.id === selectedSchedule.vaultId)}
          />
        ) : (
          <BackupVaultCard loading={true} />
        )}
      </div>
      {policy?.schedules.length > 1 ? (
        <>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            sx={{
              margin: '0 -24px',
              marginTop: '24px',
              padding: '12px 24px',
              paddingBottom: 0,
              borderTop: '1px solid var(--mui-palette-divider)',
            }}
          >
            <Typography variant='body1'>
              {`${
                selectedSchedule
                  ? (policy?.schedules.findIndex(
                      (p) => p.id === selectedSchedule?.id
                    ) ?? 0) + 1
                  : 1
              } of ${policy?.schedules?.length ?? 0} rules`}
            </Typography>
            <Stack direction='row' alignItems='center'>
              <IconButton
                size='small'
                onClick={() => {
                  const currentIndex = selectedSchedule
                    ? policy.schedules.findIndex(
                        (s) => s.id === selectedSchedule.id
                      )
                    : 0;
                  setSelectedSchedule(
                    policy.schedules[
                      (currentIndex - 1 + policy.schedules.length) %
                        policy.schedules.length
                    ]
                  );
                }}
              >
                <i className='material-symbols-chevron-left-rounded' />
              </IconButton>
              {policy.schedules.map((schedule) => {
                const vault = vaults.find((v) => v.id === schedule.vaultId);
                const selected = selectedSchedule?.id === schedule.id;
                return (
                  <SelectableIconBtn
                    size='small'
                    key={schedule.id}
                    selected={selected}
                    onClick={() => {
                      if (!selected) {
                        setSelectedSchedule(schedule);
                      }
                    }}
                  >
                    <SnapshotIndicator
                      backgroundColor={vault?.backgroundColor || null}
                    />
                  </SelectableIconBtn>
                );
              })}
              <IconButton
                size='small'
                onClick={() => {
                  const currentIndex = selectedSchedule
                    ? policy.schedules.findIndex(
                        (s) => s.id === selectedSchedule.id
                      )
                    : 0;
                  setSelectedSchedule(
                    policy.schedules[
                      (currentIndex + 1) % policy.schedules.length
                    ]
                  );
                }}
              >
                <i className='material-symbols-chevron-right-rounded' />
              </IconButton>
            </Stack>
          </Stack>
        </>
      ) : (
        <div className='mb-[12px]'></div>
      )}
    </div>
  );
}
