import cronstrue from 'cronstrue';

const retentionText = (retention: number) => {
  switch (retention) {
    case 7:
      return '1 week';
    case 14:
      return '2 weeks';
    case 30:
      return '1 month';
    case 90:
      return '3 months';
    case 180:
      return '6 months';
    case 365:
      return '1 year';
    case 730:
      return '2 years';
    default:
      return `${retention} days`;
  }
};

const frequencyCronText = (cron: string) => {
  if (cron === '0 0 * * *') {
    return 'Daily';
  } else if (cron === '0 0 * * 0') {
    return 'Weekly';
  } else if (cron === '0 0 1 * *') {
    return 'Monthly';
  } else if (cron === '0 0 1 1 *') {
    return 'Yearly';
  }

  return cronstrue.toString(cron, {
    verbose: true,
  });
};

export { retentionText, frequencyCronText };
