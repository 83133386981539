import { Box, Stack } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import { Tag } from '../tag';

const useObserveElementWidth = <T extends HTMLElement>() => {
  const [width, setWidth] = useState(0);
  const ref = useRef<T>(null);

  useEffect(() => {
    let observerRefValue = null;
    const observer = new ResizeObserver((entries) => {
      setWidth(entries[0].contentRect.width);
    });

    if (ref.current) {
      observer.observe(ref.current);
      observerRefValue = ref.current;
    }

    return () => {
      if (observerRefValue) {
        observer.unobserve(observerRefValue);
      }
    };
  }, []);

  return {
    width,
    ref,
  };
};

export const TagsElasticContainer = ({
  tags,
}: {
  tags: Record<string, string>;
}) => {
  const [shownTagsCount, setShownTagsCount] = useState<number>(0);

  const { ref, width } = useObserveElementWidth<HTMLDivElement>();

  useEffect(() => {
    if (width > 0 && ref.current?.childNodes.length) {
      const childrenElements = Array.from(
        ref.current.childNodes
      ) as HTMLElement[];

      const firstItemTopDistance =
        childrenElements[0].getBoundingClientRect().y;

      setShownTagsCount(
        childrenElements.filter(
          (x) => x.getBoundingClientRect().y === firstItemTopDistance
        ).length - 1 // need to compensate for the '+10' tag
      );
    }
  }, [ref, width]);

  if (!Object.entries(tags || {}).length) {
    return null;
  }

  const values = Object.entries(tags).map((entry) =>
    entry.filter((x) => x).join('=')
  );
  const hiddenTagsCount = values.length - shownTagsCount;

  return (
    <Box className='relative'>
      <Stack
        ref={ref}
        gap='8px'
        rowGap='20px'
        direction='row'
        flexWrap='wrap'
        className='py-[10px] h-[48px] overflow-hidden absolute top-0 left-0 right-0 invisible'
      >
        <Tag variant='outlined' content={`+10`} />
        {values.map((value) => (
          <Tag variant='outlined' key={value} content={value} />
        ))}
      </Stack>
      <Stack
        gap='8px'
        rowGap='20px'
        direction='row'
        flexWrap='wrap'
        className='py-[10px] h-[48px] overflow-hidden'
      >
        {values.slice(0, shownTagsCount).map((value) => (
          <Tag variant='outlined' key={value} content={value} />
        ))}
        {hiddenTagsCount > 0 && (
          <Tag variant='outlined' content={`+${hiddenTagsCount}`} />
        )}
      </Stack>
    </Box>
  );
};
