import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ClickAwayListener,
  Divider,
  FormControlLabel,
  Paper,
  Popper,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { BackupStatus, type Control } from '@repo/api-gw-sdk';
import classnames from 'classnames';
import { useRef, useState } from 'react';

import { useWorkspace } from '@/contexts/useWorkspace';
import { useDAL } from '@/data/dal';
import { BackupStatuses, ControlsSeverity } from '@/data/inventory/data';
import {
  isValueCondition,
  type Condition,
  type ValueCondition,
} from '@/types/advanceFilter';

import { BackupStatusIcon } from './backupStatusIcon';
import { createBackupStatusNotInCondition } from './createBackupStatusNotInCondition';

import {
  ControlIndicator,
  controlKeysPriorities,
} from '../controls/controlIndicator';
import { ControlsListFilter } from '../controls/controlsListFilter';
import { Panels } from '../panels/panels';
import {
  backupStatus,
  controlId,
  mutedControlId,
} from '../queryBuilder/properties';
import { Icon } from '../shared/icon';

interface FilterProps {
  backupStatus: BackupStatus;
  toggleBackupStatus: (status: BackupStatus) => void;
  selectedBackupStatuses: string[];
}

export const ViolationsDetectedFilterRow = (
  props: FilterProps & {
    selectedLevel: string | undefined;
    renderFiltersPanel: (expandedPropertyName?: string) => void;
    onFilterChange: (condition: Condition[]) => void;
    conditions: Condition[];
    selectControlLevel: (controlLevel: string) => void;
  }
) => {
  const { leftPanel } = useWorkspace();
  const dal = useDAL();
  const { body } = dal.controls.list();

  const condition = props.conditions.find(
    (x) => isValueCondition(x) && x.property === controlId.name
  );

  const mutedCondition = props.conditions.find(
    (x) => isValueCondition(x) && x.property === mutedControlId.name
  );

  const selectedResources =
    (isValueCondition(condition) && condition.value) ||
    (isValueCondition(mutedCondition) && mutedCondition.value) ||
    [];

  const severityWithFilteredControls = mutedCondition
    ? 'muted'
    : findFirstControl(
        selectedResources,
        body?.controls
      )?.severity.toLowerCase();

  const selected = props.selectedBackupStatuses.includes(props.backupStatus);

  return (
    <Accordion
      className='bg-transparent m-0 mr-[-24px]'
      expanded={selected || !!props.selectedLevel}
    >
      <AccordionSummary
        className='p-0 bg-transparent'
        sx={{
          '.MuiStack-root': {
            flexGrow: 1,
            width: '100%',
          },
          '.MuiTypography-root': {
            fontWeight: 'normal',
          },
        }}
        expandIcon={null}
      >
        <BackupStatusFilterRow {...props} />
      </AccordionSummary>
      <AccordionDetails className='p-0'>
        <Stack className='my-[12px]'>
          {controlKeysPriorities.map((priority) => {
            const selected = props.selectedLevel === priority;

            return (
              <Stack
                key={priority}
                sx={(theme) => ({
                  margin: '0 0 0 -24px',
                  paddingLeft: '32px',
                  borderLeft: '2px solid transparent',
                  borderColor: selected ? '#272742' : 'transparent',
                  backgroundColor: selected
                    ? 'var(--mui-palette-background-default)'
                    : 'transparent',
                  cursor: selected ? 'default' : 'pointer',
                  ...(selected
                    ? {}
                    : {
                        '&:hover': {
                          backgroundColor:
                            theme.palette.mode === 'dark' ? '#272742' : 'white',
                        },
                      }),
                })}
                onClick={() => props.selectControlLevel(priority)}
              >
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  sx={{
                    borderLeft: '1px solid var(--mui-palette-divider)',
                    padding: '4px 0 4px 20px',
                  }}
                >
                  <ControlIndicator
                    backgroundColor={ControlsSeverity[priority].color}
                  />
                  <Typography className='ml-[12px] flex-grow'>
                    {ControlsSeverity[priority].title}
                  </Typography>
                  {severityWithFilteredControls === priority && (
                    <Box
                      className='rounded inline-block mr-[10px] h-[8px] w-[8px]'
                      sx={{
                        backgroundColor: 'currentColor',
                      }}
                    />
                  )}
                  <Tooltip
                    title={
                      priority === 'muted'
                        ? 'Filter by muted violated controls'
                        : 'Filter by violated controls'
                    }
                  >
                    <Icon
                      iconClass='material-symbols-arrow-right-rounded'
                      className={classnames('mr-[18px]', {
                        collapse: !(
                          selected || severityWithFilteredControls === priority
                        ),
                      })}
                      onClick={(event) => {
                        leftPanel.setComponent({
                          panel: Panels.FiltersDrillDown,
                          props: {
                            title: 'Violated Controls',
                            subtitle: `Select controls from this list to filter for resources with  ${priority === 'muted' ? priority : `${priority}-severity`} violations`,
                            Component: ({ filter }) => (
                              <ControlsListFilter
                                filter={filter}
                                onChange={props.onFilterChange}
                                priority={priority}
                              />
                            ),
                            onBack: () =>
                              props.renderFiltersPanel(backupStatus.name),
                          },
                        });

                        event.stopPropagation();
                      }}
                    />
                  </Tooltip>
                </Stack>
              </Stack>
            );
          })}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export const BackupStatusFilterRow = ({
  backupStatus,
  toggleBackupStatus,
  selectedBackupStatuses,
}: FilterProps) => {
  const selected = selectedBackupStatuses.includes(backupStatus);

  return (
    <Stack
      sx={(theme) => ({
        margin: '0 -24px',
        padding: '12px 0 12px 22px',
        borderLeft: '2px solid transparent',
        borderColor: selected ? '#272742' : 'transparent',
        backgroundColor: selected
          ? 'var(--mui-palette-background-default)'
          : 'transparent',
        cursor: 'pointer',
        '.info-icon': {
          visibility: 'hidden',
        },
        '&:hover .info-icon': {
          visibility: 'visible',
        },
        ...(selected
          ? {}
          : {
              '&:hover': {
                backgroundColor:
                  theme.palette.mode === 'dark' ? '#272742' : 'white',
              },
            }),
      })}
      onClick={() => toggleBackupStatus(backupStatus)}
      direction={'row'}
      alignItems={'center'}
    >
      <BackupStatusIcon backupStatus={backupStatus} />
      <Typography className='ml-[8px]'>
        {BackupStatuses[backupStatus].title}
      </Typography>
      <Tooltip
        title={
          <Box>
            {BackupStatuses[backupStatus].description}
            {BackupStatuses[backupStatus].recommendation && (
              <Divider
                className='my-[8px]'
                sx={{
                  borderColor: 'rgba(39, 39, 66, 0.90)',
                }}
              />
            )}
            {BackupStatuses[backupStatus].recommendation && (
              <Stack direction={'row'}>
                <Icon
                  sx={(theme) => ({
                    marginRight: '8px',
                    '& i': {
                      color:
                        theme.palette.mode === 'light'
                          ? 'var(--mui-palette-common-white)'
                          : 'rgba(39, 39, 66, 0.90)',
                    },
                  })}
                  iconClass='material-symbols-circle-notifications-outline-rounded'
                />
                {BackupStatuses[backupStatus].recommendation}
              </Stack>
            )}
          </Box>
        }
        slotProps={{
          tooltip: {
            sx: {
              padding: '16px',
            },
          },
        }}
      >
        <i className='info-icon absolute right-[24px] material-symbols-info-outline h-[20px] w-[20px] p-[2px] cursor-default' />
      </Tooltip>
    </Stack>
  );
};

function findFirstControl(
  selectedResources: string[],
  controls: Control[] | undefined
) {
  for (const id of selectedResources) {
    const control = controls?.find((x) => x.id === id);
    if (control) {
      return control;
    }
  }
}

export const AdditionalStatuses = ({
  valueConditions,
  onChange,
  excludedBackupStatusCondition,
  includedBackupStatusCondition,
}: {
  excludedBackupStatusCondition?: ValueCondition;
  includedBackupStatusCondition?: ValueCondition;
  valueConditions: ValueCondition[];
  onChange: (conditions: Condition[]) => void;
}) => {
  const iconRef = useRef(null);
  const [open, setOpen] = useState(false);

  return (
    <Stack direction='row' alignItems='center' justifyContent='space-between'>
      <Typography>Additional statuses</Typography>
      <Icon
        ref={iconRef}
        iconClass='material-symbols-more-horiz'
        onClick={() => setOpen(true)}
      />
      <Popper
        open={open}
        anchorEl={iconRef.current}
        disablePortal
        placement='bottom'
        sx={{ zIndex: 1, marginTop: '-44px !important', width: '300px' }}
      >
        <Paper
          className={'shadow-lg px-[24px] py-[12px]'}
          sx={{ borderRadius: '8px' }}
        >
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <Stack gap='12px' className='pb-[8px]'>
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
              >
                <Typography className='font-semibold'>
                  Additional statuses
                </Typography>
                <Tooltip title={'Close'}>
                  <Icon
                    iconClass='material-symbols-close-rounded'
                    onClick={() => setOpen(false)}
                  />
                </Tooltip>
              </Stack>

              <Typography className='font-light mb-[8px]'>
                Resources with these statuses are hidden from your inventory by
                default. To work with the hidden resources, turn on the statuses
                below.
              </Typography>
              <HiddenStatusSwitch
                valueConditions={valueConditions}
                excludedBackupStatusCondition={excludedBackupStatusCondition}
                includedBackupStatusCondition={includedBackupStatusCondition}
                onChange={onChange}
                backupStatus={BackupStatus.Disconnected}
              />
              <HiddenStatusSwitch
                valueConditions={valueConditions}
                excludedBackupStatusCondition={excludedBackupStatusCondition}
                includedBackupStatusCondition={includedBackupStatusCondition}
                onChange={onChange}
                backupStatus={BackupStatus.ExcludedFromBackup}
              />
            </Stack>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </Stack>
  );
};

const HiddenStatusSwitch = ({
  backupStatus,
  valueConditions,
  onChange,
  excludedBackupStatusCondition,
  includedBackupStatusCondition,
}: {
  backupStatus: BackupStatus;
  excludedBackupStatusCondition?: ValueCondition;
  includedBackupStatusCondition?: ValueCondition;
  valueConditions: ValueCondition[];
  onChange: (conditions: Condition[]) => void;
}) => {
  const isBackupStatusChecked = !excludedBackupStatusCondition?.value?.includes(
    backupStatus.toString()
  );

  return (
    <FormControlLabel
      className='p-0 m-0 w-full justify-between'
      labelPlacement='start'
      checked={isBackupStatusChecked}
      onClick={() => {
        if (isBackupStatusChecked) {
          const excludedStatuses = [
            ...(excludedBackupStatusCondition?.value || []),
            backupStatus,
          ];

          const includedStatuses = includedBackupStatusCondition?.value.filter(
            (x) => x !== backupStatus.toString()
          );

          onChange(
            valueConditions
              .filter(
                (x) =>
                  x !== includedBackupStatusCondition &&
                  x !== excludedBackupStatusCondition
              )
              .concat(
                createBackupStatusNotInCondition(excludedStatuses),
                includedStatuses?.length
                  ? [
                      {
                        ...includedBackupStatusCondition!,
                        value: includedStatuses,
                      },
                    ]
                  : []
              )
          );
        } else {
          const excludedStatuses = excludedBackupStatusCondition?.value.filter(
            (x) => x !== backupStatus.toString()
          );

          onChange(
            valueConditions
              .filter((x) => x !== excludedBackupStatusCondition)
              .concat(
                excludedStatuses?.length
                  ? [createBackupStatusNotInCondition(excludedStatuses)]
                  : []
              )
          );
        }
      }}
      control={<Switch />}
      label={
        <Stack direction='row' alignItems='center' gap='12px'>
          <BackupStatusIcon backupStatus={backupStatus} />
          <Typography>{BackupStatuses[backupStatus].title}</Typography>
        </Stack>
      }
    />
  );
};
