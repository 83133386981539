const identifyDelimiter = (path: string) => {
  if (!path) return '/';
  if (path.includes('\\') || path[1] === ':') return '\\';

  return '/';
};

const getParentPath = (path: string) => {
  const delimiter = identifyDelimiter(path);
  const parentPath = path.split(delimiter).slice(0, -1).join(delimiter);
  return parentPath;
};

export { identifyDelimiter, getParentPath };
