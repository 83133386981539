import { styled } from '@mui/material';

export const SnapshotIndicator = styled('div')(
  (props: { backgroundColor: string | null; sx?: object }) => ({
    height: '12px',
    width: '12px',
    borderRadius: '50%',
    display: 'inline-block',
    minWidth: '12px',
    minHeight: '12px',
    border: props.backgroundColor !== null ? 'none' : '1px solid gray',
    backgroundColor:
      props.backgroundColor === null ? 'transparent' : props.backgroundColor,
    ...props.sx,
  })
);
