import { InputAdornment, TextField } from '@mui/material';

import { Icon } from '../shared/icon';

export const SearchTextBox = ({
  value,
  onChange,
  placeholder,
}: {
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
}) => {
  return (
    <TextField
      sx={{
        '.MuiInputBase-root': {
          paddingLeft: '4px',
          paddingRight: '4px',
        },
        '.MuiInputBase-input': {
          paddingLeft: 0,
        },
        '.MuiInputAdornment-root': {
          margin: '0px',
        },
      }}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <Icon iconClass='material-symbols-search' />
          </InputAdornment>
        ),
        endAdornment: value && (
          <InputAdornment position='end'>
            <Icon
              iconClass='material-symbols-close-rounded'
              onClick={() => onChange('')}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};
