import { StringOperator, type ValueCondition } from '@/types/advanceFilter';

import { backupStatus } from '../queryBuilder/properties';

export const createBackupStatusNotInCondition = (
  values: string[]
): ValueCondition => ({
  property: backupStatus.name,
  operator: StringOperator.NotIn,
  type: 'String',
  value: values,
});
