import { InputAdornment, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

import { Icon } from './icon';

export const TransactionalTextField = ({
  initValue,
  placeholder,
  onChange,
  allowEmptyValue,
  'data-testid': dataTestId,
  showSearchIcon,
  showClearIcon,
}: {
  initValue: string;
  placeholder?: string;
  allowEmptyValue?: boolean;
  onChange: (value: string) => void;
  'data-testid'?: string;
  showSearchIcon?: boolean;
  showClearIcon?: boolean;
}) => {
  const [value, setValue] = useState<string>(initValue);

  useEffect(() => {
    if (initValue !== value) {
      setValue(initValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initValue]);

  const commitValue = () => {
    if (!allowEmptyValue && !value) {
      setValue(initValue);
    } else {
      onChange(value);
    }
  };

  return (
    <TextField
      sx={{
        '.MuiInputBase-root': {
          ...(showSearchIcon ? { paddingLeft: '4px' } : {}),
          ...(showClearIcon ? { paddingRight: '4px' } : {}),
        },
        '.MuiInputBase-input': {
          ...(showSearchIcon ? { paddingLeft: '0px' } : {}),
        },
        '.MuiInputAdornment-root': {
          ...(showSearchIcon ? { margin: '0px' } : {}),
        },
      }}
      inputProps={{ 'data-testid': dataTestId }}
      placeholder={placeholder}
      tabIndex={1}
      size='small'
      value={value}
      onKeyUp={(event) => {
        if (event.key === 'Enter') {
          commitValue();
        }
      }}
      onChange={(event) => setValue(event.target.value)}
      onBlur={commitValue}
      InputProps={{
        startAdornment: showSearchIcon && (
          <InputAdornment position='start'>
            <Icon iconClass='material-symbols-search' />
          </InputAdornment>
        ),
        endAdornment: showClearIcon && value && (
          <InputAdornment position='end'>
            <Icon
              iconClass='material-symbols-close-rounded'
              onMouseDown={(e) => e.preventDefault()}
              onClick={() => {
                setValue('');
                onChange('');
              }}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};
