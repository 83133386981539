import { Box, Tooltip } from '@mui/material';
import type { BackupStatus } from '@repo/api-gw-sdk';

import { BackupStatuses } from '@/data/inventory/data';

export const BackupStatusIcon = ({
  backupStatus,
  showTooltip,
}: {
  backupStatus: BackupStatus;
  showTooltip?: boolean;
}) => {
  const data = BackupStatuses[backupStatus];
  if (!data) {
    return null;
  }

  const Body = (
    <Box
      component={'span'}
      className='rounded-full inline-flex p-[2px]'
      sx={{
        background: data.backgroundColor,
      }}
    >
      <i
        className={`${data.icon} h-[20px] w-[20px]`}
        style={{ background: data.iconColor }}
      />
    </Box>
  );

  return showTooltip ? <Tooltip title={data.title}>{Body}</Tooltip> : Body;
};
